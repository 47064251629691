.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
    background: #0a090d;
}
.closeb{
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #3892D6 -15.58%, #001b30 135.17%);
    border-radius: 100%;
    border: 3px solid white;
    width:50px;
    height:50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 44px;
}
.closeb:hover{
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #910086 -15.58%, #001b30 135.17%);
    outline: 3px white solid;
    outline-offset: 3px;
    border: none;
    color: white;
}