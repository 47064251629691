.pcont{
    position: relative;
    height:500px;
    width:400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5vh;

}
.pcont:hover{
    gap:7vh;
}
.pcont:hover .pbox{
transform: scale(1.2);
border-radius: 10%;
}
.pcont:hover .pbox .pimage{
    border-radius: 10%;
    filter: grayscale(0);
}
.pcont:hover .pname{
    font-size: 32px;
}
.pbox{
    position: relative;
    height:60%;
    width:75%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: linear-gradient(136deg, #9600FF -1.69%, #00FFE0 100%);
    animation-name: change;
    animation-duration: 30s;
    animation-iteration-count: infinite;
}
@keyframes change {
    0%{background: linear-gradient(136deg, #9600FF -1.69%, #00FFE0 100%);}
    100%{background: linear-gradient(26deg, #9600FF 0.4%, #00FFE0 105.36%);}
}
.pimage{
position: relative;
height:95%;
width:95%;
border-radius: 100%;
filter: grayscale(1);

}
.scont{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:2vh;
}
.pxlogo{
    height:50px;
    width:50px;
}
.pxlogo:hover{
    transform: scale(1.1);
}
.plnlogo{
    height:50px;
    width:50px;
}
.plnlogo:hover{
    transform: scale(1.1);
}
.pIlogo{
    height:50px;
    width:50px;
}
.pIlogo:hover{
    transform: scale(1.1);
    cursor: pointer;
}
.pname{
    color: white;
    font-size: 24px;
}
.prole{
    color: white;
    font-size: 20px;
}
.pdis{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pcont:hover .prole{
    font-size: 24px;
}

/************************************ 540 px ************************************/
@media screen and (max-width: 540px){
.pbox{
    height:50%;
    width:60%;
}
.pxlogo,.pIlogo,.plnlogo{
    height: 40px;
    width:40px;
}

  }
  /************************************ 420 px ************************************/
@media screen and (max-width: 420px){
    .pxlogo,.pIlogo,.plnlogo{
        height: 30px;
        width:30px;
    }
    
      }