.sqx{
    position: relative;
    background: transparent;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top:10vh;
    padding-bottom:10vh;
    width:90vw;
    height: auto;
    gap:10vh;
    font-family: 'Poppins', sans-serif;
}
.sqxhead{
  font-size: 64px;
  color:white;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
}
.sqxbody{
  position: relative;
  display:flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width:100%;
    gap:10vh;
}
/************************************ 540 px ************************************/
@media screen and (max-width: 540px){
.sqx{
  padding-top:10vh;
  padding-bottom:10vh;
  gap:10vh;
}
.sqxhead{
  font-size: 40px;
  width:90%;
}
      
        }