.bodyl {
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-cont {
    position: relative;
    display: flex;
    height: 200px;
    width: 200px;
    justify-content: center;
    background: transparent;
    align-items: center;
    padding: 20px;
    border-radius: 100%;
    overflow: hidden;
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #001b30 135.17%);
}

.rect {
    position: absolute;
    height: 800px;
    width: 800px;
    background: #0a090d;
    border-radius: 40%;
    bottom: 100%;
    animation-name: up;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    transform: rotate(360deg);
}

@keyframes up {
    0% {
        bottom: -10%;
        transform: rotate(45deg)
    }

    ;

    20% {
        bottom: 20%;
    }

    ;

    40% {
        bottom: 40%;
    }

    ;

    60% {
        bottom: 60%;
    }

    ;

    80% {
        bottom: 80%;
    }

    ;

    100% {
        bottom: 100%;
        transform: rotate(360deg)
    }

    ;
}

.logol {
    width: 150px;
    height: 150px;

}

.h2l {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    animation-name: texta;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    transform: scale(1);

}

@keyframes texta {
    0% {
        transform: scale(0.1);
    }

    100% {
        transform: rotate(1.5);
    }
}

h3 {
    font-family: 'Poppins', sans-serif;
    display: none;
}

h6 {
    font-family: 'Poppins', sans-serif;
    display: none;
}

/*********************************** 720 px ***************************/
@media screen and (max-width: 930px) {
    .logo-cont {
        height: 150px;
        width: 150px;
    }

    .logol {
        width: 100px;
        height: 150px;
    }

    h2 {
        display: none;

    }

    h3 {
        display: flex;
    }
}

/********************************* 540px *************************/
@media screen and (max-width: 540px) {

    h3 {
        display: none;
    }

    h6 {
        display: flex;
    }

}