.email{
    position: relative;
    top:1%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.emailc {
    position: relative;
    padding: 25%;
    border-radius: 20px;
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #3892D6 -15.58%, #001b30 135.17%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    animation-duration: 1s;
    animation-name: ar;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    outline: 3px solid white;
}
@keyframes ar {
    0%{outline-offset: 5px};
    50%{outline-offset: 10px};
    100%{outline-offset: 15px;}
}
.get{
    font-size: 24px;
    font-weight: bolder;
    overflow: hidden;
    color: white;
    text-align: center;
}
.pget{
    position: relative;
    text-align: center;
}
.forme{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    align-items: center;
}

.emailin{
    padding:10px 10px;
    border-radius: 10px;
    font-size:24px;
    text-align: center;
}
.sbutton{
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #7c7b7b 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #000000 -15.58%, #000000 135.17%);
}
.sbutton:hover{
    background: white;
    color: black;
    outline: 3px black solid;
    outline-offset: 2px;
}
.alert{
    position: relative;
padding: 0.4rem;
margin: 0.5rem;
color: black;
text-align: center;
font-size: 12px;
font-weight: bolder;
border-radius: 10px;
background: white;
z-index: 3;
}
.thanks{
 position: relative;
 display: flex;
 text-align: center;
 color: white;
 font-weight: 900;
 font-size: 24px;
}
/************************************ 1080 px *******************************************/
@media screen and (max-width: 1080px){
    .sbutton{
        font-size: 14px;
    }
}


/********************************* 680px **********************************************/
@media screen and (max-width: 680px){
    .email{
        width:90%;
    }
    .emailc{
        width:100%;
    }
    .get{
        font-size: 14px;
    }
    .emailin{
        font-size: 14px;
    }
    .forme{
        width: 90%;
    }
    .sbutton{
        font-size: 16px;
    }
    .thanks{
        font-size: 18px;
    }
}