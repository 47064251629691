.team{
    background: transparent;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top:10vh;
    padding-bottom:10vh;
    width:100%;
    height: auto;
    gap:10vh;
    font-family: 'Poppins', sans-serif;
}
.thead{
  font-size: 64px;
  color:white;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
}
.tbody{
  position: relative;
  display:flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap:10vh;
}
/************************************ 540 px ************************************/
@media screen and (max-width: 540px){
.team{
  padding-top:10vh;
  padding-bottom:10vh;
  gap:10vh;
}
.thead{
  font-size: 40px;
  width:90%;
}
      
        }