
.nav{
    background: linear-gradient(180deg, #c416f000 -37.74%, #0a090d00 45.03%);
    height:10vh;
    width:100vw;
    font-family: 'Poppins', sans-serif;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top:2%;
}
.imgc{
 /*background-color: red;*/
    position: relative;
    height: 80px;
    top: 5%;
} 
.imgc:hover{
    cursor:pointer;
}
.logo{
    height:95%;
}
.logos{
    display: none;
}
.navbarmob{
    display: none;
}
.navbar{
    position: absolute;
  /*  background-color: red;*/
/* height:45px;*/
 right:2%;
 top: 20px;
    display: none;
    gap: 10px;
 /*   flex-wrap: wrap;*/
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    
}   

button{
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
    font-size: 18px;
    color: white;
    padding:10px 18px;
    border-radius: 10px;
}
button:hover{
    cursor: pointer;
}
.sub-button{
    color: white;
    font-size: 24px;
    font-weight: 600;
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #001b30 135.17%);


  border: 1.5px solid;
  border-color: #9100B6;
  border-radius: 10px;
  padding:10px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Poppins', sans-serif;
}
.sub-button:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.ham{
    display: flex;
    top:2%;
}
.connectb{
    position: absolute;
    top:20%;
    right:7%;
}
/******************************************* 1080 px **********************************************/
@media screen and (max-width: 1080px){
    button{
        font-size: 18px;
        padding:9px 16px;
        border-radius: 8px;
    }
}
/***************************************** 930 px *****************************************************/
@media screen and (max-width: 930px){
    .imgc{
        top:6px;
    }
    .logo{
        width:150px;
        display: none;
    }
    .logos{
        display: flex;
        width:auto;
        height: 100%;
    }
    .navbar{
        gap: 7px;
    }
    button{
        font-size: 16px;
        padding:8px 15px;
    }
    .connectb{
        right:3%;
    }
}
/***************************************** 720 Px ***************************************/
@media screen and (max-width: 720px){
    .nav{
        
    }
    .imgc{
        top:6px;
    }
    .logo{
        width:120px;
    }
    .navbar{
        top:12px;
        gap: 5px;
    }
    button{
        font-size: 14px;
        padding:6px 12px;
        border-radius: 6px;
    }
    .logo{
        display: none;
    }
    .imgc{
        top:0%;
    }
    .logos{
        display: flex;
        width:auto;
        height: 80%;
    }
}

/**************************************************** 570 Px ************************************************/
@media screen and (max-width: 570px) {
    .navbar {
      display: none;
    }
    .sub-button{
        font-size: 18px;
        padding:10px;
        padding-left: 10px;
        padding-right: 10px;
    }
  }
/**************************************************** 570 Px ************************************************/
@media screen and (max-width: 500px) {
button{
    font-size: 10px;
}
  }
  