.product1 {
    position: relative;
    height: 600px;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.050);
    border-radius: 20px;
    overflow: hidden;
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.product1:hover {
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #3892D6 -15.58%, #9100B6 135.17%);
    transform: scale(1.1);
    cursor: pointer;
    border: .1px solid white;
    ;
}

.imagepc1 {
    position: absolute;
    top: 0%;
    height: 50%;
    width: 100%;
    background: #0a090d;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simage1 {
    width: 100%;
    height: auto;
    filter: blur(40px);
}

.simage2 {
    width: 90%;
    height: auto;
}

.ppara1 {
    position: relative;
    top: 25%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ppara1 a {
    text-decoration: none;
}

.sqxbutton {
    background: transparent;
    border-radius: 100%;
    border: 3px solid white;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 900;
}

.sqxbutton:hover {
    background: white;
    outline: 3px white solid;
    outline-offset: 3px;
    border: none;
    color: #9100B6;
}

.parah1 {
    font-size: 28px;
    font-weight: 900;
}

/************************************ 540 px ************************************/
@media screen and (max-width: 540px) {
    .product1 {
        border-radius: 30px;
    }

}

/*********************************** 420 px ***************************************/
@media screen and (max-width: 420px) {
    .parah1 {
        font-size: 24px;
    }

    .parab1 {
        font-size: 12px;
    }

    .product1 {
        height: 500px;
        border-radius: 25px;
        padding-top: 2vh;
        padding-bottom: 2vh;
        background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #3892D6 -15.58%, #9100B6 135.17%);
        border: 1px solid white;
    }

    .product1:hover {
        transform: scale(1);
    }
}