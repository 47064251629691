.about{
    position: relative;
      display:flex;
      flex-direction: column;
      align-items: center;
      padding-top:10vh;
      padding-bottom:10vh;
      width:100vw;
      height: auto;
      gap:15vh;
        font-family: 'Poppins', sans-serif;
        overflow: hidden;
  }
  .ahead{
    font-size: 64px;
    color:white;
    font-weight: 900;
  }
  .aimagec{
    position: absolute;
    display: flex;
    top:10%;
    width:100%;
    height:100%;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:20vh;
  }
  .aimage{
    position: absolute;
    top: 35%;;
    height:300px;
    width:300px;
    
    animation-name: r1-rotate;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }
  @keyframes r1-rotate {
    0%{transform: scale(1);}
    100%{transform:scale(2);}
  }

  /************************************ 540 px ************************************/
@media screen and (max-width: 540px){
.about{
  gap:10vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.ahead{
  font-size: 40px;
}
      
        }


  