.home{
    position: relative;
    width:100%;
    height:auto;
    padding-top: 20vh;
    padding-bottom:20vh;
    background-color:#0a090d;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.sqxb{
  position: relative;
display: flex;
 background: transparent;
  border: 2px solid white;
  border-radius: 15px;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  gap:10px;
}
a{
  text-decoration: none;
}
.sqxb span{
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width:30px;
  height: 30px;
  border-radius: 50%;
  color: #9100B6;
  font-size: 32px;
}
.sqxb:hover{
 transform: scale(1.05);
 background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #3892D6 135.17%);
}
.homec{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 10vh;
  padding-bottom: 20vh;
}
.upper{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:80%;
    justify-content: center;
    /*background-color: yellowgreen;*/
}
.para1{
    position: relative;
    width:60%;
    font-size:52px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100%;
    word-spacing: 15px;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    /*background-color:red ;*/
}
.para{
    position: relative;
    width:70%;
    font-size:20px;
    font-weight: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100%;
    word-spacing: 5px;
    font-family: 'Poppins', sans-serif;
    /*background-color:red ;*/
}
.etfw{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*  background-color: blue;*/
}
.etimg{
  width:100%;
}
.hc1 {
  position: absolute;
  height: 100px;
  left: 50%; /* Set initial position to the center horizontally */
  top: 50%; /* Set initial position to the center vertically */
  transform: translate(-50%, -50%); /* Center the object within its container */
  animation-name: c1;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-direction:alternate-reverse;
  transform: scale(0.1);
}

@keyframes c1 {
  0% {
    transform: rotate(0deg) translate(-250%, -250%) scale(10);
  }
  100% {
    transform: rotate(360deg) translate(-250%, -250%) scale(0); /* Complete a full circle */
  }
}
.hc2{
  position: absolute;
  height:100px;
  right:50%;
  transform: rotate(220deg);
  animation-name: c2;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-direction:alternate-reverse;
  transform: scale(0.1);
}
@keyframes c2 {
  0% {
    transform: rotate(0deg) translate(250%, 250%) scale(10);
  }
  100% {
    transform: rotate(360deg) translate(250%, 250%) scale(0); /* Complete a full circle */
  }
}
.hc3{
  position: absolute;
  height:100px;
  right:80%;
  transform: rotate(220deg);
  animation-name: c3;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-direction:alternate-reverse;
  transform: scale(0.1);
}
@keyframes c3 {
  0% {
    transform: rotate(0deg)  scale(0);
  }
  100% {
    transform: rotate(360deg) scale(2); /* Complete a full circle */
  }
}
.hc4{
  position: absolute;
  height:100px;
  left:80%;
  transform: rotate(220deg);
  animation-name: c3;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-direction:alternate-reverse;
  transform: scale(0.1);
}
@keyframes c3 {
  0% {
    transform: rotate(0deg)  scale(0);
  }
  100% {
    transform: rotate(360deg) scale(2); /* Complete a full circle */
  }
}

.imagec{
  position: absolute;
  display: flex;
  top:10%;
  width:100%;
  height:100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top:20vh;
}
.image{
  position: absolute;
  top: 20%;;
  height:500px;
  width:500px;
  filter: blur(150px);
  animation-name: rotate;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes rotate {
  0%{transform: scale(1);}
  100%{transform:scale(2);}
}

/************************************ 1080 px ****************************/
@media screen and (max-width: 1080px){

  .para{
    font-size: 20px;
  }
}
/************************************ 930 px ****************************/
@media screen and (max-width: 930px){

  .para{
    font-size: 20px;
  }
}

/************************************* 720px *********************************/
@media screen and (max-width: 720px){

  .upper{
    display: flex;
    flex-direction: column;
  }
  .etimg{
    width:80%;
  }
  .para{
    font-size: 24px;
    width:100%;
  }
  .para1{
    width:100%;
    
  }
}
/************************************ 600 px ************************************/
@media screen and (max-width: 600px){
.sqxb{
  font-size: 20px;
}
  .para{
    font-size: 18px;
  }
  .para1{
    font-size: 40px;
  }
}
/*********************************** 420 px ***************************************/
@media screen and (max-width: 420px){
  .sqxb{
    font-size: 16px;
    border-radius: 10px;
  }

  .para{
    font-size: 14px;
  }
}