.sub-email{
    position: relative;
    top:1%;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100vw;

    
}
.sub-emailc {
    position: relative;
    padding: 2%;
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #3892D6 135.17%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width:100%;
}

.sub-get{
    font-size: 40px;
    font-weight: bolder;
    overflow: hidden;
    color: white;
    text-align: center;
}
.pget{
    position: relative;
    text-align: center;
}
.sub-forme{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    gap:20px;
}

.emailin{
    padding:10px 10px;
    border-radius: 10px;
    font-size:24px;
    text-align: center;
}
.sbutton{
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #7c7b7b 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #000000 -15.58%, #000000 135.17%);
}
.sbutton:hover{
    background: white;
    color: black;
    outline: 3px black solid;
    outline-offset: 2px;
}
.alert{
    position: relative;
padding: 0.4rem;
margin: 0.5rem;
color: black;
text-align: center;
font-size: 12px;
font-weight: bolder;
border-radius: 10px;
background: white;
z-index: 3;
}
.thanks{
 position: relative;
 display: flex;
 text-align: center;
 color: white;
 font-weight: 900;
 font-size: 24px;
}
/************************************ 1080 px *******************************************/
@media screen and (max-width: 1080px){
    .sbutton{
        font-size: 14px;
    }
}


/********************************* 680px **********************************************/
@media screen and (max-width: 680px){
    .email{
        width:90%;
    }
    .emailc{
        width:100%;
    }
    .sub-get{
        font-size: 24px;
    }
    .emailin{
        font-size: 14px;
    }
    .sub-forme{
        flex-direction: column;
        gap:0px;
    }
    .sbutton{
        font-size: 16px;
    }
    .thanks{
        font-size: 18px;
    }
}