.rbody1{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:90%;
    height:auto;
}
.rect1{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
   width:70%;
   height:auto;
   padding:10vh;
   padding-left: 5vw;
   padding-right: 5vw;
   border-radius: 30px;
   background: rgba(255, 255, 255, 0.050);
   text-align: center;
   gap:3vh;
   backdrop-filter: blur(10px);
}
.rect1-head{
    color:white;
    font-size: 32px;
    font-weight: 900;
}
.rect1-para{
    color: white;
    font-size: 24px;
    font-weight: 200;
}
.r1-hc1{
    position: absolute;
    top:10%;
    height:100px;
    right:85%;
    transform: rotate(220deg);
    animation-name: c3;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-direction:alternate-reverse;
    transform: scale(0.1);
  }
  @keyframes c3 {
    0% {
      transform: rotate(0deg)  scale(0);
    }
    100% {
      transform: rotate(360deg) scale(2); /* Complete a full circle */
    }
  }
  .r1-hc2{
    position: absolute;
    height:100px;
    left:85%;
    top:90%;
    transform: rotate(220deg);
    animation-name: c3;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-direction:alternate-reverse;
    transform: scale(0.1);
  }
  @keyframes c3 {
    0% {
      transform: rotate(0deg)  scale(0);
    }
    100% {
      transform: rotate(360deg) scale(2); /* Complete a full circle */
    }
  }


  

/************************************ 540 px ************************************/
@media screen and (max-width: 540px){
.rect1-head{
    font-size: 28px;
}
.rect1-para{
    font-size: 20px;
}
.rect1{
  width: 90%;
}
    
      }

      /*********************************** 420 px ***************************************/
@media screen and (max-width: 420px){

  .rect1-para{
    font-size: 14px;
  }
}
