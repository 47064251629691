.twc{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width:95%;
}
.tcursor{
    font-size: 64px;
    display: inline-block;
    animation: blink 0.8s infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }  
  .twtext{
    font-family: 'Poppins', sans-serif;
    font-size: 64px;
  }

  /************************************ 900 px *******************************************/
@media screen and (max-width: 900px){
  .twc{


    width:99%;
    height:20vh;
    padding-top: 0vh;
    padding-bottom: 10vh;

  }
  .twtext{
    font-family: 'Poppins', sans-serif;
    font-size: 52px;
    
  }
}
  /************************************ 700 px *******************************************/
@media screen and (max-width: 500px){
  .twc{
    width:99%;
    height:20vh;
    padding-top: 0vh;
    padding-bottom: 15vh;

  }
  .twtext{
    font-family: 'Poppins', sans-serif;
    font-size: 52px;
    
  }
}