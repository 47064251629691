.hamicon {
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #001b30 135.17%);
    border: 2px solid #9100B6;
    position: fixed;
    bottom: 5%;
    left: 2%;
    height: 30px;
    ;
    width: 30px;
    /* background-color:#FFFFFF1A;*/

    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    cursor: pointer;
    z-index: 1;

}

.hamicon:hover {
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #001b30 135.17%);
    border: 2px solid #9100B6;
}

.i1 {
    height: 5px;
    width: 30px;
    background-color: white;
    border-radius: 10px;
    transition: 0.5s;
}

.ni1 {
    height: 5px;
    width: 30px;
    background-color: white;
    border-radius: 10px;
    transform: rotate(45deg);
    position: relative;
    top: 40%;
    right: 0%;
    transition: transform 1s;

}

.ni2 {
    display: none;
}

.ni3 {
    height: 5px;
    width: 30px;
    background-color: white;
    border-radius: 10px;
    transform: rotate(-45deg);
    position: relative;
    left: 0%;
    bottom: 40%;
    transition: transform 1s;
}

.hamnav {
    background-color: #0a090d;
    position: fixed;
    left: 0%;
    width: 100vw;
    height: 100vh;
    border-radius: 0%;
    top: 100%;
    border: 5px solid #9100B6;

    /*  left: 30.93%;
    right: 0%;
    top: 11%;
    bottom: 0%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-name: navdis1;
    animation-duration: 1s;
    transition: 1s;
    z-index: 1;
}

@keyframes navdis1 {
    from {
        top: 10%;
        border-radius: 100px;
    }

    to {
        top: 100%;
        border-radius: 0%;
    }
}

.hamnav1 {
    background-color: #0a090d;
    border: 5px solid #9100B6;
    position: fixed;
    left: 0%;
    width: 100%;
    height: 100vh;
    border-radius: 100px;
    top: 10%;
    /*  left: 30.93%;
    right: 0%;
    top: 11%;
    bottom: 0%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-name: navdis;
    animation-duration: 1s;
    transition: 1s;
    z-index: 1;
}

/*@keyframes navdis {
    from{top:-80%}
    to{top:64px}
}*/
@keyframes navdis {
    from {
        top: 100%
    }

    to {
        top: 10%
    }
}

.hamnavbar {
    /*  background-color: white;*/
    width: 90%;
    top: 10%;
    gap: 30px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.hButton {
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
    font-size: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 1.5px solid;
    border-color: #9100B6;
    border-radius: 10px;

    /* 1 */

    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #001b30 135.17%);
    border-radius: 10px;
}

.hButton:hover {
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #001b30 135.17%);
    transform: scale(1.05);
    cursor: pointer;
}

.sButton {
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
    font-size: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 1.5px solid;
    border-color: #3892D6;
    border-radius: 10px;

    /* 1 */

    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #3892D6 -15.58%, #001b30 135.17%);
    border-radius: 10px;
}

.sButton:hover {
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #3892D6 -15.58%, #001b30 135.17%);
    transform: scale(1.05);
    cursor: pointer;
}

.linkb {
    text-decoration: none;
}

/***************************************** 930 px *****************************************************/
@media screen and (max-width: 930px) {
    .hamnav1 {}
}

/***************************************** 720 px *****************************************************/
@media screen and (max-width: 720px) {
    .hamnav {}

    .hamnav1 {}
}

/***************************************** 530 px *****************************************************/
@media screen and (max-width: 530px) {
    .hamnav1 {
        border-radius: 50px;
    }

    .hamnav {
        border-radius: 50px;
    }
}