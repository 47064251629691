.Footer{
    position: relative;
    height:auto;
    padding-bottom:5vh;
    background: #0a090d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: white;
}

.fupper{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding:5vh 0vw;
    overflow: hidden;
    color: white;
    height: auto;
    padding-bottom: 5vh;
}
.flower{
    position: relative;
    top:10%;
}
.fleft{
    position: relative;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
  /*  background-color: red;*/
}

.footerlogo{
    /*background-color: red;*/
       position: relative;
       left:0%;
   } 
   .footerlogo:hover{
       cursor:pointer;
   }
.ftlogo{
    height: 80px;
}
.lname{
    position: relative;
}
.tagline{
    position: relative;
    font-size: 20px;
    a{
        color:#3892D6;
        font-weight: 900;
    }

}
.flink{
    display: flex;
    flex-direction: column;
}
.flink a{
    text-decoration: none;
    color: white;
}

/************************************************ 1320px ************************************************/
@media screen and (max-width:1400px) {

}
/************************************************ 1080 px **********************************************/
@media screen and (max-width: 1080px){

}


/************************************************ 930px ************************************************/
@media screen and (max-width: 930px){
    .tagline{
        font-size: smaller;
    }
    .flink{
        font-size: 14px;
    }
}
/*********************************************** 680px **************************************************/
@media screen and (max-width: 680px){

    .fupper{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .fleft{
        justify-content: center;
        text-align: center;
        width:90%;
        height:35vh;
    }
    .fright{
        justify-content: center;
        align-items: center;
        width:90%;
        height:35vh;
    }
    .tagline{
        font-size: 16px;
    }
    .flink{
        font-size: 14px;
    }
    .ftlogo{
        height:80px;
        width:95%;
    }

}
