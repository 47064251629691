.sm{
    position: fixed;
    height:25vh;
    width:auto;
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #001b30 135.17%);

    top:30%;
    border-radius: 20px 0px 0px 20px;
    right:0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:3vh;
    padding:10px 10px;
    cursor: pointer;
}
.smbox{
    position: relative;
    height:35%;
    width:50%;
    
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #7c7b7b 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #000000 -15.58%, #000000 135.17%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding:5px 15px;
}
.smbox:hover{
    transform: scale(1.1);
}
.xlogo{
    position: relative;
    height:25px;
    width:25px;
}
.ilogo{
    position: relative;
    height:30px;
    width:30px;
}
/********************************* 500 *******************************/
@media screen and (max-width: 500px){
    .sm{
        height:30vh;
        padding:2px 2px;
    }
    .smbox{
        height:20%;
        width:30%;
        padding: 3px 12px;
    }
}