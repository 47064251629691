.Line {
    position: relative;
    width: 100%;
    height: 20vh;
    background: #0a090d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.fline {
    position: relative;
    width: 90%;
    height: 10px;
    border-radius: 10px;
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #3892D6 -15.58%, #001b30 135.17%);
}

.footerc {
    position: absolute;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0a090d;
    overflow: hidden;
    background: radial-gradient(72.22% 72.22% at 50.33% -10%, #FFF 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(178deg, #9100B6 -15.58%, #001b30 135.17%);

}

.flogo {
    width: 50px;
    height: 50px;

}